import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import {
  P,
  SectionHeading,
  SectionSmallHeading,
  SectionWrapper,
} from "../../../styles";
const Botheeadings = styled.div`
  display: flex;
  padding: 0 0 40px 0;
  align-items: center;
`;
const Heading = styled.span`
  font-family: "Poppins-Medium";
  font-size: ${(props) => (props.fs ? props.fs : "65px")};

  color: #fff;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  text-shadow: 1px -1px #00a03d, -2px 0px #af1a06;
  color: #fccb00;
`;
const Contract = styled.div`
  padding: 40px 0;
  color: #fccb00;
  font-size: 20px;
  font-weight: 600;
`;
const TBA = styled.span`
  font-weight: 100;
  font-size: 18px;
  color: #fff;
`;
const Contract1 = styled.div`
  padding: 10px 0;
  color: #fccb00;
  font-size: 20px;
  font-weight: 600;
`;
const TBA1 = styled.span`
  font-weight: 100;
  font-size: 18px;
  color: #fff;
`;
const DragonMovement = styled.div`
  position: relative;
  width: 100%;
  // height:100vh;
  @media (max-width: 700px) {
    display: none;
  }
`;
const JadeDragon = styled.div`
  position: absolute;
  bottom: 80%;
  left: 370px;

  @media screen and (max-width: 1200px) {
    bottom: 89%;
    left: 300px;
  }
  @media screen and (max-width: 1100px) {
    bottom: 85%;
    left: 210px;
  }
  @media screen and (max-width: 899px) {
    bottom: 95%;
    // right:300px;
    left: 200px;
  }
  // @media screen and (max-width: 900px) {
  //   right: 0px;
  //   top: 150px;
  // }
  @media screen and (max-width: 700px) {
    display: none;
  }
`;

const Rectangle = styled.div`
  margin: 80px 0px 0 200px;
  background-image: url("/images/rectangle.png");
  background-size: contain;
  background-repeat: no-repeat;
  padding: 40px 0px 0 0;
  @media screen and (min-width: 1528px) {
    margin: 114px 0px 0 262px;
  }
  @media screen and (max-width: 1370px) {
    margin: 80px 0px 0 100px;
  }
  @media screen and (max-width: 1270px) {
    margin: 80px 0px 0 10px;
  }
  @media screen and (max-width: 570px) {
    margin: 10px 0px 0 10px;
    padding: 20px 0px 0 0;
  }
`;
const DragonImage = styled.img`
  width: 100%;
  height: auto;
  position: relative;
  max-width: 100%;
  /* top: -12%;
  left: 22%; */

  /* @media screen and (max-width: 1100px) {
    bottom: 0%;
    top: 18%;
    left: 23%;
    max-width: 600px;
  } */
  @media screen and (max-width: 899px) {
   
    /* display: none; */
  }
  // @media screen and (max-width: 900px) {
  //   top:0px;
  //   left: 150px;
  // }
  @media screen and (max-width: 599px) {
    display: none;
  }
  // max-width: 2000px;
`;
const Image = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  max-width: 20%;
  top: 0%;
  left: 0%;

  
  @media(max-width: 599px) {
    max-width: 50%;
  top: 12%;
  left: 0%;
  }
`;

const index = () => {
  return (
    <div>
      <SectionWrapper p="0 0 0px 0" mt="0px" id="tokenomics">
        <Container maxWidth="xl">
          <Grid container sx={{ position: "relative" ,display:"flex",justifyContent:"space-between"}}>
            <Grid item xs={12} sm={5}>
              <Image src="./images/Tokenbg.png"/>
              <SectionHeading>Tokenomics</SectionHeading>
              <Contract1>DISCLOSURES AND LEGALEASE</Contract1>
              <P fs="12px">
                Investing in cryptocurrencies involves risks, including the
                potential loss of your investment. The value of cryptocurrencies
                can be highly volatile, and market conditions can change
                rapidly. We recommend conducting thorough research and
                consulting with financial professionals before making any
                investment decisions. However, with risk comes opportunity, and
                by embracing the potential of cryptocurrencies like $JADE, you
                can seize exciting possibilities and embrace the future of
                decentralized finance.
              </P>
              <Contract1>
                CONTRACT:<TBA1>&nbsp;&nbsp;TBA</TBA1>{" "}
              </Contract1>
              <Rectangle>
                <marquee
                  width="60%"
                  direction="up"
                  height="70px"
                  behavior="scroll"
                  scrollamount="4"
                >
                  <SectionSmallHeading fs599="40px" fs="55px">
                    Name
                  </SectionSmallHeading>
                  <P fs="15px" p="0" m="0">
                    Yù Lóng 玉龙
                  </P>
                  <SectionSmallHeading fs599="40px" fs="55px">
                    Symbol
                  </SectionSmallHeading>
                  <P fs="15px" p="0" m="0">
                    $JADE
                  </P>
                  <SectionSmallHeading fs599="40px" fs="55px">
                    Supply
                  </SectionSmallHeading>
                  <P fs="15px" p="0" m="0">
                    888888888
                  </P>
                  <SectionSmallHeading fs599="40px" fs="55px">
                    Taxes
                  </SectionSmallHeading>
                  <P fs="15px" p="0" m="0">
                    10/25 eventually 2/2 into Ip or 1/1 into Ip
                  </P>
                  <SectionSmallHeading fs599="40px" fs="55px">
                    Max TX
                  </SectionSmallHeading>
                  <P fs="15px" p="0" m="0">
                    1%
                  </P>
                  <SectionSmallHeading fs599="40px" fs="55px">
                    Max Wallet
                  </SectionSmallHeading>
                  <P fs="15px" p="0" m="0">
                    2%
                  </P>
                </marquee>
              </Rectangle>
            </Grid>
            <Grid item xs={12} sm={5} sx={{display:"flex",justifyContent:"end"}}>
              <DragonImage src="/images/jadedragon.png" />
            </Grid>
          </Grid>
          {/* <Botheeadings>
            <Heading>$&nbsp;</Heading>
            <SectionSubHeading>
              Jade
              <br /> dragon
            </SectionSubHeading>
          </Botheeadings> */}

          {/* <Contract1>
            Total supply:<TBA1>&nbsp; 10 Million</TBA1>{" "}
          </Contract1>
          <Contract1>
            Taxes:<TBA1>&nbsp;2/4</TBA1>{" "}
          </Contract1>
          <Contract1>
            Max Buy:<TBA1>&nbsp;1%</TBA1>{" "}
          </Contract1>
          <Contract1>
            Max Wallet:<TBA1>&nbsp;2%</TBA1>{" "}
          </Contract1> */}
          {/* <P maxWidth="50%">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
            sadipscing elitr.
            <br />
            <br />
            sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
            aliquyam erat, sed diam voluptua. At vero eos et accusam et justo
            duo dolores et ea rebum.
          </P> */}

          {/* <Contract>
            CONTRACT:
            <TBA>
              <a
                style={{
                  textDecoration: "none",
                }}
                href="https://etherscan.io/address/0xa3066314f5993506307badbeccdacbf1d7f356d5"
                target="blank"
              >
                &nbsp;0xa...6d5
              </a>
            </TBA>{" "}
          </Contract> */}
        </Container>
      </SectionWrapper>
    </div>
  );
};

export default index;
