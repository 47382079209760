import React from "react";

import { P } from "../../../styles";
import Menu from "./menu";
import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";


const ReadMore = styled.a`
  text-decoration: none;
  background-color: #fff;
  cursor: pointer;
  background: linear-gradient(to top, rgb(98, 177, 76), rgb(4, 154, 72));
  border-radius: 11px;
  padding: 10px 30px 10px 30px;
  font-family: "Poppins-Regular";
  font-size: 14px;
  margin-right: 3px;
  /* box-shadow: ${(props) =>
    props.bs ? props.bs : "inset 0px 2px 5px 0px #FED0AE"}; */
  &:hover {
    opacity: 0.8;
  }
`;
const View = styled.a`
  text-decoration: none;
  background-color: #fff;
  cursor: pointer;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 11px;
  padding: 10px 30px 10px 30px;
  font-family: "Poppins-Regular";
  font-size: 14px;
  margin-right: 3px;
  &:hover {
    opacity: 0.8;
  }
`;

const Intro = styled(Grid)`
  justify-content: center;

  padding: 50px 0 0 0;
  display: flex;
  align-items: ${(props) => (props.alignitem ? props.alignitem : "end")};
  @media screen and (max-width: 899px) {
    padding: ${(props) => (props.p599 ? props.p599 : "")};
  }
`;
const Text = styled(Grid)`
  padding: 0px 0 0 0;
  display: flex;
  align-items: end;
  justify-content: center;
  @media screen and (max-width: 899px) {
    padding: ${(props) => (props.p599 ? props.p599 : "")};
  }
`;
const VideoBK = styled.video`
  /* width: 100%;
  height: auto;
  display: block; */
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
`;


const Index = () => {
  return (
    <div>
      <VideoBK autoPlay loop muted>
        <source src="/images/bg.mp4" type="video/mp4" />
      </VideoBK>
      <Menu />
      {/* <Background> */}
      <Container maxWidth="xl">
        <Grid container>
          <Intro xs={12} md={4} lg={3} p599="150px 0 0 0">
            {/* <P>
              The Jade Dragon has combined its mystical powers with the
              legendary Wu Kong Monkey King to fight against the evil forces in
              crypto and slay evil beings that create chaos in the cryptoverse.
              <br />
              <br />
              <ReadMore href="mailto:info@jadedragon.finance" >
                Contact us
              </ReadMore>
            </P> */}
          </Intro>
          <Intro xs={0} md={4} lg={6} alignitem="center" sx={{ minHeight:"calc(100vh - 150px)"}}>
            {/* <HomeImage src="/images/homePageImg.png" /> */}
          </Intro>
          <Intro xs={0} md={4} lg={3}></Intro>
          <Text xs={12} md={3}></Text>
          <Text xs={12} md={6}>
            <P fs="12px" style={{ textAlign: "center" }}>
              Throughout history, tales of dragons have captivated the
              imaginations of people worldwide. Like other legendary creatures,
              dragons have been interpreted differently across various cultures.
            </P>
          </Text>

          <Text xs={12} md={3}></Text>
          <Text xs={12} md={2}></Text>
          <Text xs={12} md={8}>
            <div>
              <ReadMore>Buy Now</ReadMore>
              <View>Buy Now</View>
            </div>
          </Text>

          <Text xs={12} md={2}></Text>
        </Grid>
      </Container>
      {/* </Background> */}
    </div>
  );
};

export default Index;
