import styled from "@emotion/styled";
import { Container } from "@mui/material";
import React from "react";
import { SectionHeading, SectionSmallHeading } from "../../../styles";

const BackgroundImage = styled.div`
  /* padding:100px 0;  */
  background: url("/images/lastbg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
`;
const Background = styled.div`
  display: flex;
  align-items: center;
  min-height: calc(100vh - 50px);
  padding: ${(props) => (props.p ? props.p : "0")};
  margin: ${(props) => (props.m ? props.m : "0")};
  display: flex;
  align-items: center;
`;
const Socials = styled.div`
  display: flex;
  align-items: center;
`;
const Footer = styled.div`
  text-align: center;
  color: #fccb00;
`;
const Logos = styled.a`
  width: 35px;
  padding-left: 25px;
  @media screen and (max-width: 340px) {
    padding-left: 15px;
  }
`;
const SocialImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 40px;
`;
const CommunityImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 330px;
`;
const index = () => {
  return (
    <div>
      <BackgroundImage>
        <Background>
          <Container maxWidth="xl">
            <CommunityImage src="/images/jadecomunity.png" />
            <SectionHeading>JOIN OUR COMMUNITY</SectionHeading>
            <Socials>
              <SectionSmallHeading>JOIN US:</SectionSmallHeading>
              <Logos href="https://t.me/jadedragon_eth" target="blank">
                <SocialImage src="/images/telegram.png" />
              </Logos>
              <Logos href="" target="blank">
                <SocialImage src="/images/Twitter.png" />
              </Logos>
              {/* <Logos
                href=""
                target="blank"
              >
                <SocialImage src="/images/medium.png" />
              </Logos> */}
            </Socials>
          </Container>
        </Background>
        <Footer>Copyright © 2023 yulong.finance</Footer>
      </BackgroundImage>
    </div>
  );
};

export default index;
