import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { Link } from "@mui/material";
import logo from "./mainNavLogo.png";
import { MenuLink, MobileMainMenu, SiteLogo } from "./styles";
export default function MobileMenu(props) {
  const [state, setState] = useState({ top: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <MobileMainMenu>
      {/* <React.Fragment> */}
      <Link to="/">
        {/* <SiteLogo src={logo} /> */}
        <SiteLogo src="./images/logo.png" />
      </Link>

      {state["left"] ? (
        <Button onClick={toggleDrawer("left", false)}>
          <span
            className="material-symbols-outlined"
            style={{ color: "#ffffff", fontSize: 30 }}
          >
            menu_open
          </span>
        </Button>
      ) : (
        <Button onClick={toggleDrawer("left", true)}>
          <span
            className="material-symbols-outlined"
            style={{ color: "#ffffff", fontSize: 30 }}
          >
            menu
          </span>
        </Button>
      )}
      <Drawer
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        <Box
          sx={{
            width: 950,
            background: "#000000",
            minHeight: "calc( 100vh - 30px )",
            textAlign: "center",
            paddingTop: "120px",
          }}
          role="presentation"
          onClick={toggleDrawer("left", false)}
          onKeyDown={toggleDrawer("left", false)}
        >
          {/* <Link href="/" className="d-inline-block pt-4 pl-4">
              <SiteLogo src={logo} width="100" />
            </Link> */}
          <Divider />
          <List>
            {props.menuList.map((text, index) => (
              <MenuLink
                key={index}
                href={text[1]}
                className={text[3] + " d-block"}
              >
                {text[0]}
              </MenuLink>
            ))}
          </List>
        </Box>
      </Drawer>
      {/* </React.Fragment> */}
    </MobileMainMenu>
  );
}
