import React from "react";

import DesktopMenu from "./deskTopMenu";
import MobileMenu from "./mobileMenu";
import { MenuContainer } from "./styles";

const MenuList = [
  [
    "Buy Now",
    "",
  ],
  [
    "View Chart",
    "",
  ],
  ["Tokenomics", "/#tokenomics"],
  ["Join Telegram", "https://t.me/jadedragon_eth","blank"],
];

const Menu = () => {
  return (
    <MenuContainer>
      <DesktopMenu menuList={MenuList} />
      <MobileMenu menuList={MenuList} />
    </MenuContainer>
  );
};

export default Menu;
